export const isLoading = (state) => state.auctions.loading;

// All auctions
export const selectAuctions = (state) => state.auctions.auctions;
// Recently Created Auction
export const recentlyCreatedAuction = (state) => state.auctions.auction;

// Different Auction Creation State flags
// Successfully created the auction
export const hasSuccessfullyPublished = (state) =>
  state.auctions.successfullyPublished;
// Auction is Scheduled
export const successfullyScheduledAuction = (state) =>
  state.auctions.successfullyScheduled;
// Auction is drafted
export const successfullyDraftedAuction = (state) =>
  state.auctions.successfullyDrafted;
// Auction has been updated
export const successfullyUpdatedAuction = (state) =>
  state.auctions.successfullyUpdatedAuction;
// Auction failed to get updated
export const failedUpdateAuction = (state) => state.auctions.failedUpdate;
// Auction Code Generation
export const selectAuctionCode = (state) => state.auctions.auctionCode;

export const selectSavingDraft = (state) => state.auctions.savingDraft;

export const selectProgressFilter = (state) => state.auctions.progressFilter;

export const selectCurrentPageFilter = (state) => state.auctions.currentPage;

export const selectAuctionCounts = (state) => state.auctions.counts;
