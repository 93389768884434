/* eslint-disable react/require-default-props */
import React, { useState, useEffect } from "react";
import cn from "classnames";
import { navigate } from "gatsby";
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../../../../../store";
import {
  clearIsEditing,
  closeCreateAuctionModal,
  resetAuctionModalState,
} from "../../../../../../store/auctionModal/reducer";
import styles from "./AuctionComponents.module.scss";
import CloseButton from "./CloseButton";
import {
  getAuction,
  getAuctionModalIsEditing,
  getCurrentPath,
} from "../../../../../../store/auctionModal/selectors";
import { Auction } from "../../../../../../types/Auction";
import { AuctionPartial } from "../../../../../../store/auctionModal/actions";
import { User } from "../../../../../../types/User";
import { selectUserInfo } from "../../../../../../store/user/selectors";
import {
  fetchAuctions,
  patchAuction,
  saveAuctionPartial,
  ScheduledAuction,
} from "../../../../../../store/auctions/actions";
import {
  failedUpdateAuction,
  hasSuccessfullyPublished,
  recentlyCreatedAuction,
  successfullyDraftedAuction,
  successfullyUpdatedAuction,
} from "../../../../../../store/auctions/selectors";
import {
  getIndividualAuction,
  StoreAuctionPartial,
} from "../../../../../../store/individualAuction/actions";
import StepDots from "../../../../../StepDots";
import {
  resetAuctions,
  resetSuccessfullyPublished,
  setSavingDraft,
} from "../../../../../../store/auctions/reducer";
import { toggleIndividualAuctionModal } from "../../../../../../store/individualAuction/reducer";
import { sendToast } from "../../../../../../utils/helpers";
import { PathTypes } from "../../NewAuctionCreation/Types/PathTypes";

interface AuctionCreationNavProps {
  title?: string;
  step?: number;
  totalSteps?: number;
  onBack?: () => any;
  isConfirmation?: boolean;
  backTitle?: string;
  disableDraft?: boolean;
  hideDots?: boolean;
}
const AuctionCreationNav = ({
  title,
  step,
  totalSteps,
  onBack,
  isConfirmation = false,
  backTitle = "Back",
  disableDraft = false,
  hideDots = false,
}: AuctionCreationNavProps) => {
  const userInfo = useSelector((state) => selectUserInfo(state)) as User;
  const auction = useSelector((state) => getAuction(state)) as Auction;
  const isEditing = useSelector((state) =>
    getAuctionModalIsEditing(state)
  ) as boolean;
  const hasUpdated = useSelector((state) =>
    successfullyUpdatedAuction(state)
  ) as boolean;
  const hasFailedUpdate = useSelector((state) =>
    failedUpdateAuction(state)
  ) as boolean;
  const [complete, setComplete] = useState(false);
  const [loadingAnimation, setLoadingAnimation] = useState(false);
  const createdAuction = useSelector((state) =>
    recentlyCreatedAuction(state)
  ) as ScheduledAuction;
  const hasPublished = useSelector((state) =>
    hasSuccessfullyPublished(state)
  ) as boolean;
  const hasDrafted = useSelector((state) =>
    successfullyDraftedAuction(state)
  ) as boolean;
  const path = useSelector((state) => getCurrentPath(state));

  const dispatch = useAppDispatch();
  const [showDraftModal, setShowDraftModal] = useState(false);

  useEffect(() => {
    if (hasDrafted) {
      // handle here
      setComplete(true);
    }
  }, [hasDrafted]);

  useEffect(() => {
    if (hasFailedUpdate) {
      setComplete(true);
    }
  }, [hasFailedUpdate]);

  useEffect(() => {
    if (hasUpdated) {
      setComplete(true);
      // Save the draft if its a draft
    }
  }, [hasUpdated]);

  useEffect(() => {
    if (hasPublished && createdAuction) {
      dispatch(resetSuccessfullyPublished());
      setComplete(true);
    }
  }, [hasPublished, createdAuction]);

  const getConfirmState = () => {
    if (disableDraft) {
      closePage();
      return;
    }
    const isHomeAndNoTitle = !auction && !auction.name && !isEditing;
    if (isHomeAndNoTitle) {
      closePage();
    } else if (!isConfirmation) {
      setShowDraftModal(true);
    } else {
      closePage();
    }
  };

  const handleSaveDraft = () => {
    // Draft Auction
    if (!auction.name) {
      sendToast(
        "Please enter a title for your auction before you save as a draft.",
        { type: "warning" }
      );
      return;
    }
    setLoadingAnimation(true);
    const newAuct = JSON.parse(JSON.stringify(auction));
    newAuct.progress = "draft";
    const auctionPartial = {
      auction: newAuct,
      userInfo,
    } as AuctionPartial;
    Object.preventExtensions(newAuct);
    dispatch(setSavingDraft(true));
    if (isEditing) {
      dispatch(patchAuction(auctionPartial));
    } else {
      // Create Auction
      dispatch(saveAuctionPartial(auctionPartial));
    }
  };

  const closePage = () => {
    setLoadingAnimation(false);
    setShowDraftModal(false);
    dispatch(clearIsEditing());
    dispatch(toggleIndividualAuctionModal(false));
    dispatch(closeCreateAuctionModal());
    dispatch(fetchAuctions({ id: userInfo?.data?.last_store_id }));
    dispatch(resetSuccessfullyPublished());
    dispatch(resetAuctionModalState());
    dispatch(resetAuctions());
    if (isEditing && auction.id) {
      const storeAuctionPartial = {
        storeId: userInfo.data.last_store_id,
        auctionId: auction.id,
      } as StoreAuctionPartial;
      dispatch(getIndividualAuction(storeAuctionPartial));
      navigate(`/dashboard/auctions/${auction.id}/`);
    }
    if (!isEditing) {
      if (path === PathTypes.AUCTIONS) {
        navigate(`/dashboard/auctions/`);
      } else {
        navigate("/dashboard/");
      }
    }
  };

  const discard = () => {
    if (isEditing && auction.id) {
      const { id } = auction;
      setLoadingAnimation(false);
      setShowDraftModal(false);
      dispatch(clearIsEditing());
      dispatch(toggleIndividualAuctionModal(false));
      dispatch(closeCreateAuctionModal());
      navigate(`/dashboard/auctions/${id}/`);
    } else {
      setLoadingAnimation(false);
      setShowDraftModal(false);
      dispatch(toggleIndividualAuctionModal(false));
      dispatch(clearIsEditing());
      dispatch(closeCreateAuctionModal());
      if (path === PathTypes.AUCTIONS) {
        navigate(`/dashboard/auctions/`);
      } else {
        navigate("/dashboard/");
      }
    }
  };

  const getUpdateText = () => {
    if (complete) {
      if (hasFailedUpdate) {
        return "Update Failed";
      }
      return "Saved Draft!";
    }
    return "Saving Draft";
  };

  return (
    <>
      <div className={styles.auctionCreationNav}>
        <div className={styles.auctionNavInnerContainer}>
          <button
            type="button"
            onClick={() => onBack()}
            className="text-bidddyPink"
          >
            {backTitle}
          </button>
          <div>
            <p className="text-center">{title}</p>
            {!hideDots && (
              <StepDots numberOfSteps={totalSteps} currentStep={step} />
            )}
          </div>
          <CloseButton onClick={() => getConfirmState()} />
        </div>
      </div>
      {showDraftModal && (
        <div className={styles.draftModalContainer}>
          <div className={styles.draftPopup}>
            <div className={styles.draftPopupContainer}>
              {!loadingAnimation && (
                <>
                  <p>
                    Would you like to save this auction as a draft before you
                    exit?
                  </p>
                  <button
                    type="button"
                    className="text-bidddyPink"
                    onClick={() => handleSaveDraft()}
                  >
                    {isEditing ? "Save Draft" : "Save Draft"}
                  </button>
                  <button
                    type="button"
                    className="text-bidddyAltGray"
                    onClick={() => discard()}
                  >
                    {isEditing ? "Discard Changes" : "Discard"}
                  </button>
                  <button
                    type="button"
                    className="text-bidddyAltGray"
                    onClick={() => setShowDraftModal(false)}
                  >
                    Cancel
                  </button>
                </>
              )}
              {loadingAnimation && (
                <>
                  <div
                    className={cn([
                      {
                        [styles.circleLoader]:
                          loadingAnimation && !hasFailedUpdate,
                        [styles.loadComplete]: complete && !hasFailedUpdate,
                      },
                      styles.loaderContainer,
                    ])}
                  >
                    {complete && !hasFailedUpdate && (
                      <div className={cn([styles.checkmark, styles.draw])} />
                    )}
                    {complete && hasFailedUpdate && (
                      <div className={styles.svgbox}>
                        <svg
                          className={cn([styles.circular, styles.redstroke])}
                        >
                          <circle
                            className={styles.path}
                            cx="40"
                            cy="40"
                            r="30"
                            fill="none"
                            strokeWidth="5"
                            strokeMiterlimit="10"
                          />
                        </svg>
                        <svg className={cn([styles.cross, styles.redstroke])}>
                          <g transform="matrix(0.79961,8.65821e-32,8.39584e-32,0.79961,-502.652,-204.518)">
                            <path
                              className={styles.firstline}
                              d="M634.087,300.805L673.361,261.53"
                              fill="none"
                            />
                          </g>
                          <g transform="matrix(-1.28587e-16,-0.79961,0.79961,-1.28587e-16,-204.752,543.031)">
                            <path
                              className={styles.secondline}
                              d="M634.087,300.805L673.361,261.53"
                            />
                          </g>
                        </svg>
                      </div>
                    )}
                  </div>
                  <p className="text-center">{getUpdateText()}</p>
                </>
              )}
              {complete && (
                <button
                  type="button"
                  className={styles.close}
                  onClick={() => closePage()}
                >
                  Close
                </button>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AuctionCreationNav;
