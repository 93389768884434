import React from "react";
import cn from "classnames";
import styles from "./StepDots.module.scss";

const StepDots = ({ numberOfSteps, currentStep }) => {
  const getRows = () => {
    const rows = [];
    for (let i = 0; i < numberOfSteps; i += 1) {
      rows.push(
        <div
          key={`dot-${i}`}
          className={cn([styles.dot, { [styles.selected]: currentStep === i }])}
        />
      );
    }
    return rows;
  };

  return <div className={styles.stepDotsContainer}>{getRows()}</div>;
};

export default StepDots;
