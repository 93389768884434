import React from "react";
import { navigate } from "gatsby";
import { useSelector } from "react-redux";
import { AuthProvider } from "../../../../components/Auth/auth";
import SimpleSpring from "../../../../components/Transitions/transitions";
import DashboardLayout from "../../../../components/DashboardLayout";
import ConnectBidddy, {
  LocationTypes,
} from "../../../../components/Onboarding/ConnectBidddy";
import AuctionCreationNav from "../../../../components/PageComponents/Dashboard/Auctions/AuctionCreation/internal/AuctionCreationNav";
import {
  getAuction,
  getAuctionModalIsEditing,
} from "../../../../store/auctionModal/selectors";
import { Auction } from "../../../../types/Auction";
import { MethodTypes } from "../../../../enums/MethodTypes";

const ConnectPage = ({ location }) => {
  const auction = useSelector((state) => getAuction(state)) as Auction;
  const isEditing = useSelector((state) =>
    getAuctionModalIsEditing(state)
  ) as boolean;
  const handleBack = () => {
    if (auction.type === MethodTypes.code) {
      if (isEditing) {
        navigate("/dashboard/auctions/edit/add-settings/");
        return;
      }
      navigate("/dashboard/auctions/create/add-settings/");
    } else {
      if (isEditing) {
        navigate("/dashboard/auctions/edit/preview/");
        return;
      }
      navigate("/dashboard/auctions/create/preview/");
    }
  };

  return (
    <AuthProvider>
      <SimpleSpring location={location}>
        <DashboardLayout
          style={{ paddingTop: 0 }}
          hideNav
          hideFooter
          title="Bidddy | Create Auction - Connect Bidddy and Instagram"
        >
          <AuctionCreationNav
            step={4}
            totalSteps={6}
            onBack={() => handleBack()}
            title="Connect to Instagram"
          />
          <ConnectBidddy
            location={LocationTypes.AuctionCreation}
            title="You're almost there..."
          />
        </DashboardLayout>
      </SimpleSpring>
    </AuthProvider>
  );
};

export default ConnectPage;
