import React from "react";
import cx from "classnames";
import styles from "./AuctionComponents.module.scss";

const CloseButton = ({ onClick, extraClasses = null }) => (
  <button
    type="button"
    className={cx([styles.closeButton, extraClasses])}
    aria-label="Close"
    onClick={onClick}
  />
);

export default CloseButton;
