import React, { useEffect, useState } from "react";
import { navigate } from "gatsby";
import cn from "classnames";
import Imgix from "react-imgix";
import { useSelector } from "react-redux";
import styles from "./Onboarding.module.scss";
import { useAppDispatch } from "../../store";
import {
  selectInstagramBasicAccounts,
  selectInstagramBasicUrl,
} from "../../store/user/selectors";
import {
  getInstagramBasicAuthUrl,
  InstagramBasicAccounts,
  InstagramBasicInterface,
} from "../../store/user/actions";
import { getAuction } from "../../store/auctionModal/selectors";
import { Auction } from "../../types/Auction";
import { MethodTypes } from "../../enums/MethodTypes";
import RoundedButton from "../Buttons/RoundedButton";
import LoadingSmall from "../Loading/LoadingSmall";
import { setLocation } from "../../store/user/reducer";
import { sendToast } from "../../utils/helpers";

export const LocationTypes = {
  Account: "Account",
  AuctionCreation: "AuctionCreation",
  Dashboard: "Dashboard",
};

const StandingImage =
  "https://imgix.cosmicjs.com/eda4e4b0-3f46-11ec-9828-0f2f00f103e0-bidfootstanding-1.png";

const ConnectBidddy = ({
  title = "You're almost there...",
  location = LocationTypes.AuctionCreation,
}) => {
  // Check the "Auction Type"
  const dispatch = useAppDispatch();
  const auction = useSelector((state) => getAuction(state)) as Auction;
  const instagramBasicUrl = useSelector((state) =>
    selectInstagramBasicUrl(state)
  ) as InstagramBasicInterface | null;
  const instagramAccounts = useSelector((state) =>
    selectInstagramBasicAccounts(state)
  ) as InstagramBasicAccounts[] | null;
  const [checkingAccounts, setCheckingAccounts] = useState(false);
  const [localLoading, setLocalLoading] = useState(false);

  useEffect(() => {
    const currentStateObject = {
      type: "AUTHORIZE",
      navigationPath: location,
    };
    const url = `auth-redirect/`;
    const stateData = `${encodeURIComponent(
      JSON.stringify(currentStateObject)
    )}`;
    const object = {
      url,
      state: stateData,
    };
    // Handle stuff.
    dispatch(getInstagramBasicAuthUrl(object));
  }, []);

  const onAuthorize = () => {
    if (localLoading) {
      return;
    }
    setLocalLoading(true);
    if (instagramBasicUrl) {
      dispatch(setLocation(location));
      const { url } = instagramBasicUrl;
      window.location.href = url;
    }
    setLocalLoading(false);
  };

  useEffect(() => {
    if (instagramAccounts && instagramAccounts.length > 0) {
      // TODO: handle the scenario of multiple different accounts
      navigate("/dashboard/getting-started/connected/", {
        state: { navigationPath: location },
      });
    }
    if (
      checkingAccounts &&
      instagramAccounts &&
      instagramAccounts.length === 0
    ) {
      setCheckingAccounts(false);
      sendToast(
        "There was an error connecting your Instagram Account or a cancelation in the authorization. Please try authorizing again.",
        { type: "error" }
      );
    }
  }, [instagramAccounts, checkingAccounts]);

  const getAuctionContent = () => {
    if (auction.type && auction.type === MethodTypes.code) {
      // Handle CODE view
      return (
        <>
          <p>
            To use Bidddy with Instagram we&apos;ll first need to verify that
            you have an Instagram{" "}
            <span className={styles.pinkCopy}>
              Business Account or a Creator Account.
            </span>
          </p>
          <br />
          <p>
            The fastest way to verify what kind of account you have is to{" "}
            <strong>
              authorize Bidddy to acess your Profile Info on Instagram.
            </strong>
          </p>
          <RoundedButton
            onClick={() => onAuthorize()}
            disabled={instagramBasicUrl === null || localLoading}
            id="submit"
          >
            Authorize Now
          </RoundedButton>
        </>
      );
    }
    // Handle DEFAULT view
    return (
      <>
        <p>
          To use Bidddy with Instagram we&apos;ll first need to verify that you
          have an Instagram{" "}
          <span className={styles.pinkCopy}>Business Account.</span>
        </p>
        <RoundedButton
          onClick={() => onAuthorize()}
          disabled={instagramBasicUrl === null || localLoading}
          id="submit"
        >
          {!localLoading && "Authorize Now"}
          {localLoading && <LoadingSmall />}
        </RoundedButton>
      </>
    );
  };

  return (
    <div
      className={cn([
        styles.howItWorksOnboardingContainer,
        styles.connectBidddyContainer,
        styles.defaultContainer,
        { [styles.creationImage]: location === LocationTypes.AuctionCreation },
      ])}
    >
      {/* {location === LocationTypes.Account && (<Timeline />)} */}
      <div className={cn([styles.congratsImageContainer])}>
        <Imgix
          src={`${StandingImage}?auto=format`}
          width={208}
          height={326}
          htmlAttributes={{
            alt: `Connect Account`,
            src: `${StandingImage}?auto=format`,
          }}
          attributeConfig={{
            src: "data-src",
            srcSet: "data-srcset",
            sizes: "data-sizes",
          }}
        />
      </div>
      <h3>{title}</h3>
      <h2>Let&apos;s Connect Bidddy to Instagram</h2>
      {getAuctionContent()}
    </div>
  );
};

export default ConnectBidddy;
