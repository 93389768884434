/* eslint-disable react/button-has-type */
import React from "react";
import cn from "classnames";
import styles from "./Button.module.scss";

const RoundedButton = ({
  onClick,
  children,
  disabled = false,
  extraClass = null,
  type = "button",
  ...props
}: any) => (
  <button
    type={type}
    disabled={disabled}
    className={cn([extraClass, styles.roundedButton])}
    onClick={onClick}
    {...props}
  >
    {children}
  </button>
);

export default RoundedButton;
